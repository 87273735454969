var blogjs = (function($){

  var _domEl = {
    // $buttons: $('.plusbutton--light, .plusbutton--dark, .ecrbutton--light, .ecrbutton--dark, .ecrbutton--grey' ),
  };

  var $grid;



  var gridinitnew = function() {
    $grid = $('.grid');
    $grid.imagesLoaded(function() {
      $grid.isotope({
        // layoutMode: 'packery',
        itemSelector: '.grid-item',
        masonry: {
          columnWidth: '.grid-sizer',
          horizontalOrder: true
        },
        // packery: {
        //   gutter: '.gutter-sizer',
        //   columnWidth: ".grid-sizer"
        // },
        percentPosition: true
      });
      $grid.isotope('layout');
    });
  };


  // var gridinit = function(){
  //     // set up and make addressable masonry in $grid variable
  //   $grid = $('.grid').masonry({
  //     itemSelector: '.grid-item',
  //     columnWidth: '.grid-sizer',
  //     percentPosition: true
  //   });
  //   $grid.masonry('layout'); // initial layout

  //   // re-layout as images load
  //   $grid.imagesLoaded().progress( function() {
  //     $grid.masonry('layout');
  //   });
  // };


  var searchfieldclass = function(){
    var $element = $('.search-field'),
        $target  = $('.search-form');
    // console.log( $element );
    if ($element.val().length > 0) {
      $target.addClass('this--formfilledout');
    } else {
      $target.removeClass('this--formfilledout');
    }
  };

  // var regrid = function(){
  //   // re-layout as images load
  //   $grid.imagesLoaded().progress( function() {
  //     $grid.masonry('layout');
  //   });
  // };


  var regridnew = function(){
    // re-layout as images load
    
    console.log('running callback function');
    console.log('running callback function');

    $grid.imagesLoaded(function() {
      $grid.isotope('layout');
    });
  };


 /**
  * Public Methods
  */
  return{
    // gridinit: gridinit,
    gridinitnew: gridinitnew,
    // regrid: regrid,
    regridnew: regridnew,
    searchfieldclass: searchfieldclass
  };

})(jQuery);

