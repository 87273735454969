var floorplansJs = (function($) {
  var _init = function() {
    $('.fpnavitem__img').on('click', function(e) {
      $('[data-icon="zoom"]').trigger('click');
    });
    $('.floorplansgrid__wrapper').magnificPopup({
      delegate: '[data-icon="zoom"]',
      type: 'image',
      showCloseBtn: true,
      fixedContentPos: true,
      callbacks: {
        elementParse: function(item) {
          // console.log(item);
          item.src = $(item.el).parents('.fpnavitem__wrapper').find('.fpnavitem__img').attr('src');
        },
        buildControls: function() {
          // re-appends controls inside the main container
          //this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
        }
      },
      tLoading: 'Loading image #%curr%...',
      mainClass: 'gallery__mfp',
      gallery: {
        enabled: true,
        arrowMarkup: '<button title="%title%"] type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button

        tPrev: 'Previous (Left arrow key)', // title for left button
        tNext: 'Next (Right arrow key)', // title for right button
        tCounter: '<span class="mfp-counter">%curr% of %total%</span>',
        navigateByImgClick: true,
        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        cursor: null,
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        titleSrc: null
      }
    });
    $('[data-icon="lease"]').on('click', function(e) {
      var $ctrler = $('.jshook_leasebutton > a');
      var leaseLink = $ctrler.attr('href');
      if (leaseLink) {
        window.open(leaseLink, '_blank');
      }
    });
    $('[data-icon="tour"]').on('click', function(e) {
      var $parentCtrler = $(this).parents('.fpnavitem__wrapper');
      var handoutLink = $parentCtrler.attr('data-handout');
    });
    // removed, because I needed the 'download' attribute on the link per Liz
    // $('[data-icon="save"]').on('click', function(e) {
    //   e.preventDefault();
    //   var $parentCtrler = $(this).parents('.fpnavitem__wrapper');
    //   var handoutLink = $parentCtrler.attr('data-handout');
    //   if (handoutLink) {
    //     window.open(handoutLink, '_blank');
    //   }
    // });
    $('[data-icon]').on('click', function(e) {
      if($(this).data('icon') !== 'save'){
        e.preventDefault();
      }
    });
  };
  return {
    init: function() {
      _init();
    },
    finalize: function() {

    }
  };
})(jQuery);
