var parentsJs = (function($) {
  var prevElementHeight = 0;
  function scrollToElement($element) {
    var scrollEvents = 'scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove';
    var $page = $('html, body');
    // stop animation on scroll input from user
    $page.on(scrollEvents, function(){
      $page.stop();
    });
    $page.animate({
      scrollTop: $element.offset().top - $('.primaryNav__logoinner').height() - prevElementHeight
    }, 500).promise()
      .then(function() {
        $page.off(scrollEvents);
      });
  }
  function _init() {
    $('.faq__dd').on('click', function(e) {
      e.preventDefault();
      var $curThis = $(this);
      // prevElementHeight = $('.faq__dt.active').outerHeight(true) + $('.faq__dd.active').outerHeight(true);
      prevElementHeight = $('.faq__dt.active').outerHeight(true);

      $curThis.siblings().removeClass('active');
      if( $curThis.hasClass('active') ){
        $curThis.add($curThis.next()).removeClass('active');
      }else{
        $curThis.add($curThis.next()).addClass('active');
        if( $(window).width() < 768 ){
          // I was originally using this, because the animation of the layers will actually change the offset().top 
          // of the clicked element by the element that is already open. Alternatively, I ended up storeing the height
          // of the previously open element, and subtract it from the scroll.
          // setTimeout(function(){
            scrollToElement($curThis);
          // }, 500);
        }
      }
      
      
    });
  }
  function _finalize() {

  }
  return {
    init: _init,
    finalize: _finalize,
  };
})(jQuery);
