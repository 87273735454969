var buttonsjs = (function($){

  var _domEl = {
    // $buttons: $('.plusbutton--light, .plusbutton--dark, .ecrbutton--light, .ecrbutton--dark, .ecrbutton--grey' ),
  };

  var hover = function(input){
    // adapted and re-written for jQuery from directional script by 
    // - Noel Delgado | @pixelia_me
    var nodes  = document.querySelectorAll(input);

    var $jqnodes = $(input);

    var _getDirection = function (ev, $obj) {
      var w = $obj.width(),
      h = $obj.height(),
      x = (ev.pageX - $obj.offset().left - (w / 2) * (w > h ? (h / w) : 1)),
      y = (ev.pageY - $obj.offset().top - (h / 2) * (h > w ? (w / h) : 1)),
      d = Math.round( Math.atan2(y, x) / 1.57079633 + 5 ) % 4;
      
      return d;
    };
    var _addClass = function ( ev, $obj, state ) {
      var direction = _getDirection( ev, $obj ),
      class_suffix = '';

      switch ( direction ) {
        case 0 : class_suffix = '-top';    break;
        case 1 : class_suffix = '-right';  break;
        case 2 : class_suffix = '-bottom'; break;
        case 3 : class_suffix = '-left';   break;
      }
      $obj.addClass(state + class_suffix);

    };

    $jqnodes.on('mouseover', function(ev){
      $jqnodes.removeClass('out-top').removeClass('out-right').removeClass('out-bottom').removeClass('out-left');
      _addClass( ev, $(this), 'in' );
    });
    $jqnodes.on('mouseout', function(ev){
      $jqnodes.removeClass('in-top').removeClass('in-right').removeClass('in-bottom').removeClass('in-left');
      _addClass( ev, $(this), 'out' );
    });

  };

 /**
  * Public Methods
  */
  return{
    hover: hover
  };

})(jQuery);

