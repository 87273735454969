var photoJs = (function($){
  var _domEle = {
    galleryZoomImageSelector: '.gallery-main__images.active .zoom-images-gallery',
    $galleryZoomImages : function(){
      return $(this.galleryZoomImageSelector);
    },
    magnificPopupObj: {
      type: 'image',
      gallery:{
        enabled:true
      },
      image:{
        cursor: null
      }
    }
  };
  return{
    init: function(){
      var $gallery_images = $('.gallery-main img');
      objectFitImages($gallery_images);
      jcf.setOptions('Select',{
        wrapNative : false,
        wrapNativeOnMobile: false,
        fakeDropInBody: false
      });
      jcf.replaceAll();
      _domEle.$galleryZoomImages().magnificPopup(_domEle.magnificPopupObj);
      $('.gallery-main__selector--select select').on('change', function(){
        var filterValue = $(this).val();
        $('[data-filter="'+filterValue+'"]').trigger('click');
      });
      $('[data-filter]').on('click',function(e){
        e.preventDefault();
        if(!$(this).hasClass('active')){
          $('.active[data-filter]').removeClass('active');
          $(this).addClass('active');
          console.log('call ajax');
          var gallery_selector = $(this).data('filter');
	        var page_id = $('.gallery-main').data('page-id');
          console.log(gallery_selector);
          if($('[data-content="'+gallery_selector+'"]').length <= 0 ){
            $.ajax({
              url: siteobj.ajaxurl,
              type: 'POST',
              data:{
                'action' : 'get_gallery_images',
                'data_tax': gallery_selector,
		            'page_id': page_id,
                'security': siteobj.checknonce
              },
              success: function(data){
                $('.active[data-content]').removeClass('active');
                $('.gallery-main-wrapper').append(data);
                _domEle.$galleryZoomImages().magnificPopup(_domEle.magnificPopupObj);
              },
              error: function(error){
                console.log(error);
              }
            });
          }
          else{
            $('.active[data-content]').removeClass('active');
            $('[data-content="'+gallery_selector+'"]').addClass('active');
          }
        }
        else{
          console.log('clicked on active');
        }
      });

      // detect IE8 and above, and edge and add Class to gallery-main
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        $('.gallery-main').addClass('ie-edge');
      }

    },
    finalize: function(){}
  };
})(jQuery);
