var reviewsjs = (function($){

  var _domEl = {
    $shareopeners: $('.sharecontainer__activator' ),
    $shareicons:   $('.sharecontainer__icons' ),
    $mg_container: $('#reviewscontainer' ),
  };

  var _shareIcons = function(){
    $('body').on('click', function(e){
      if(!$(e.target).is('.sharecontainer__activator')) {
        _domEl.$shareicons.removeClass('this--active');
      }
    });
    _domEl.$shareopeners.on('click', function(){
      _domEl.$shareicons.removeClass('this--active');
      $(this).siblings('.sharecontainer__icons').addClass('this--active');
    });
  };


  var _reviewRouter = function(){

    socialiconsmarkup = $('#socialshareiconscontainer').html();

    var temproutes = {};
    temproutes[MMCGlobal.reviewsSlug+'/:review_category(/)'] = 'rwmethod';

    var FpAppRouter = Backbone.Router.extend({
      /* define the route and function maps for this router */
      routes: temproutes,

      // function with the variables defined and grabbed from the URL above
      rwmethod: function(review_category) {
        if(review_category){
          review_category = review_category.replace("page", "");
          _domEl.$mg_container.removeClass('loaded');
        }

        jQuery.ajax({
            method: "GET",
            url: MMCGlobal.ajaxurl,
            data: {
              action: "mmc_get_reviewsdata", // function in /lib/mmc/mmc_ajax.php
              d: {
                page: review_category,       // passed in the function call
              },
              mmc_ajax_post_id: MMCGlobal.current_post_id,
            }
          })
          // callback
          .done(function(msg) {
            // display content from printFloorplans(), 
            // called by mmc_get_reviewsdata in /lib/mmc/mmc_ajax.php
            _domEl.$mg_container.html(msg).addClass('loaded');
            $('.sharecontainer__icons').html(socialiconsmarkup);
          });
        }
    });

    // init Router stuff
    floorplansRouter = new FpAppRouter();

    Backbone.history.start({
      hashChange: false,
      pushState: true
    });

    $(document).on('click', 'a.reviewsRouter', function(e) {
      e.preventDefault(); // This is important
      var href = $(e.currentTarget).attr('href');
      // console.log( href );
      floorplansRouter.navigate(href, true); // <- this part will pass the path to the router
    });
  };




  var init = function(){
    // _reviewRouter();
    _shareIcons();
  };

 /**
  * Public Methods
  */
  return{
    init: init
  };

})(jQuery);

