var homeJs = (function($) {
  function initSlickSlider($element, unslick) {
    var unslickCheck = unslick || false;
    $wrapper = $element.find('.homecarousel__wrapper');
    if ($wrapper.hasClass("slick-initialized") && unslickCheck) {
      $wrapper.slick('unslick');
    }
    if (!$wrapper.hasClass("slick-initialized")) {
      var childElements = $wrapper.children().length;
      $wrapper.slick({
        // arrows: false,
        prevArrow: '.homecarousel__container .fa-angle-double-left',
        nextArrow: '.homecarousel__container .fa-angle-double-right',
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        rows: 0,
      });
    }
  }
  return {
    init: function() {
      // $(window).resize(_.debounce(function() { initSlickSlider($('.locnav__catitem.active'), true); }, 250));
      initSlickSlider($('.homecarousel'));

    },
    finalize: function() {

    },
  };
})(jQuery);
