var loadLocationsPage = function($) {
  if (window.MMCLocation === undefined) {
    return;
  }
  $.getScript(
    'https://maps.googleapis.com/maps/api/js?key=' + MMCLocation.googleMapKey,
    function() {
      var getMarkerScale = function() {
        var designWidth = 1400;
        var scaledSize = window.innerWidth / 1400;
        var maximumScale = 4;
        var minimumScale = 2;
        if (scaledSize < minimumScale) {
          return minimumScale;
        }
        if (scaledSize > maximumScale) {
          return maximumScale;
        }
        return scaledSize;
      };

      MMCLocation.categorySelector = '.locationnav__catitem.active';
      // MMCLocation.activeCategory = $(MMCLocation.categorySelector)
      //   .data('category');
      MMCLocation.locationMarkers = {};
      // MMCLocation.locationMarkers[MMCLocation.activeCategory] = [];
      MMCLocation.markerProperties = {};
      MMCLocation.markerProperties.delay = 100;
      MMCLocation.homeMapMarker = null;
      MMCLocationHotSpots.color = '#111111';
      MMCLocationHotSpots.strokeColor = '#000000';
      MMCLocationHotSpots.colorActive = '#cfa155';

      var googleMapCanvas = document.getElementById('googlemap');
      var myLatLng = MMCLocation.latLng;
      delete myLatLng.address;
      Object.keys(myLatLng).forEach(function(elem) {
        myLatLng[elem] = parseFloat(myLatLng[elem]);
      });
      var mapProp = {
        center: {
          lat: parseFloat(myLatLng.lat),
          lng: parseFloat(myLatLng.lng)
        },
        zoom: 14,
        scrollwheel: false,
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
        },
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        }
      };
      var map = new google.maps.Map(googleMapCanvas, mapProp);

      var setMapStyles = function(map, styleObj) {
        var styledMap = new google.maps.StyledMapType(styleObj, {
          name: 'map_style'
        });
        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');
      };
      setMapStyles(map, MMCLocation.mapStyles);

      function getMarkerDimensionsLiteral(svgElem) {
        if (typeof svgElem !== typeof undefined) {
          var svgViewportArr = svgElem.getAttribute('viewBox').split(' ');
          return {
            width: parseFloat(svgViewportArr[2]),
            height: parseFloat(svgViewportArr[3])
          };
        }
        return {
          width: 36,
          height: 36
        };
      }


      // gets defined later on in script
      var afterAllMarkersHaveDropped = function() {};
      function clearOverlays() {
        Object.keys(MMCLocation.locationMarkers).forEach(function(key,
          index) {
          MMCLocation.locationMarkers[key].forEach(function(elem) {
            elem.setMap(null);
          });
        });
      }

      function getMarkerPosition(marker) {
        if (marker.getPlace() && marker.getPlace().location) {
          return marker.getPlace().location;
        }
        return marker.getPosition();
      }

      function showAllMarkers() {
        //     //clearInfoBoxes(infoBoxes);
        var activeCategoryMarkers = Object.keys(MMCLocation.locationMarkers[
          MMCLocation.activeCategory]);
        if (activeCategoryMarkers.length === 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        $.each(activeCategoryMarkers, function(idx, elem) {
          bounds.extend(
            getMarkerPosition(
              MMCLocation.locationMarkers[MMCLocation.activeCategory][elem]
            )
          );
        });
        bounds.extend(MMCLocation.homeMapMarker.getPosition());
        map.fitBounds(bounds);
        var listener = google.maps.event.addListener(map, "idle", function() {
          google.maps.event.removeListener(listener);
        });
        map.panBy(1, 0);
      }
      //
      var setMarkerActive = function(_activeCategory, marker, locElement) {
        MMCLocation.locationMarkers[_activeCategory].forEach(function(
          elem, idx) {
          elem.setZIndex(5 + idx);
          elem.getIcon().fillColor = MMCLocationHotSpots.color;
          // elem.getIcon().scale = 17 / 78;
          elem.setIcon(elem.getIcon());
        });
        marker.getIcon().fillColor = MMCLocationHotSpots.colorActive;
        // marker.getIcon().scale = (17 / 78) * 2;
        marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
        marker.setIcon(marker.getIcon());
        map.panTo(marker.getPosition());
        // console.log(locElement);
      };
      //
      var addMarkerMethod = function(activeElem) {
        // console.log(activeElem);
        clearOverlays();
        if (MMCLocation.activeCategory in MMCLocation.locationMarkers) {
          MMCLocation.locationMarkers[MMCLocation.activeCategory].forEach(
            function(elem) {
              elem.setMap(map);
              elem.setVisible(true);
              elem.setAnimation(google.maps.Animation.DROP);
            });
          showAllMarkers();
          return;
        }
        listOfPlaces = activeElem.querySelectorAll(MMCLocation.categorySelector + ' .slick-slide:not(.slick-cloned).locationnav__hotspotitem');
        MMCLocation.locationMarkers[MMCLocation.activeCategory] = [];
        Array.prototype.forEach.call(listOfPlaces, function(elem, idx) {
          (function(_activeCategory) {
            setTimeout(function() {
              var locationId = elem.getAttribute('data-fpid');
              var latLng = MMCLocationHotSpots.locations[locationId];
              latLng = {
                lat: latLng[0],
                lng: latLng[1]
              };
              var thePlaceId = elem.querySelector('.locationnav__directions').href.split('cid=')[1];
              MMCLocation.locationMarkers[_activeCategory][
                locationId
              ] = new google.maps.Marker({
                position: latLng,
                animation: google.maps.Animation.DROP,
                optimized: false,
                icon: {
                  //               //http://complexdan.com/svg-circleellipse-to-path-converter/
                  path: 'M-15,0a15,15 0 1,0 30,0a15,15 0 1,0 -30,0',
                  fillColor: MMCLocationHotSpots.color,
                  fillOpacity: 1,
                  strokeWeight: 2,
                  strokeColor: MMCLocationHotSpots.strokeColor,
                  // anchor: new google.maps.Point(73.4/2, 98.2),
                  scale: 0.8,
                },
                draggable: false,
                map: map,
                zIndex: (5 + idx),
                visible: _activeCategory === MMCLocation.activeCategory,
              });
              //
              var categoryMarker = MMCLocation.locationMarkers[_activeCategory][locationId];
              //
              if (thePlaceId) {
                categoryMarker.setPlace({
                  location: latLng,
                  placeId: thePlaceId
                });
              }

              // categoryMarker.addListener('mouseout', function() {
              //   this.getIcon().fillColor = MMCLocationHotSpots.color;
              //   this.setIcon(this.getIcon());
              // });
              // categoryMarker.addListener('mouseover', function() {
              //   this.getIcon().fillColor = MMCLocationHotSpots.colorActive;
              //   this.setIcon(this.getIcon());
              // });

              $(listOfPlaces[idx]).on('click', (function(_marker, __activeCategory, thisLocItem) {
                return function() {
                  setMarkerActive(__activeCategory, _marker, thisLocItem);
                  // $('.locationnav__catitem.active .sub-menu').slick('slickGoTo', $(thisLocItem).attr('data-slick-index'), false);
                };
              })(categoryMarker, _activeCategory, listOfPlaces[idx]));

              google.maps.event.addListener(categoryMarker,
                'click', (function(marker, _idx, locitems, _locationId) {
                  return function() {
                    setMarkerActive(_activeCategory, marker, locitems[_idx]);
                    // $('.locationnav__catitem.active .slick-slider')
                    //   .slick('slickGoTo', _idx);
                    $('.locationnav__catitem.active .locationnav__hotspotitem').removeClass('active');
                    $(locitems[_idx]).addClass('active');
                    var $targetSlide = $('.locationnav__catitem.active').find('[data-slick-index="' + _idx + '"]');
                    console.log($targetSlide.hasClass('slick-active'));
                    if (!$targetSlide.hasClass('slick-active')) {
                      $('.locationnav__catitem.active .sub-menu').slick('slickGoTo', _idx);
                    }
                  };
                })(categoryMarker, idx, listOfPlaces, locationId));

                if (Object.keys(MMCLocation.locationMarkers[
                    _activeCategory]).length === listOfPlaces.length) {
                  afterAllMarkersHaveDropped();
                }
            }, idx * MMCLocation.markerProperties.delay);
          })(MMCLocation.activeCategory);
        });
      };
      //
        afterAllMarkersHaveDropped = function() {
          showAllMarkers();
        };
      //
      //   // var marker = new google.maps.Marker({
      //   //   position: mapProp.center,
      //   //   map: map,
      //   //   optimized: false,
      //   //   icon: {
      //   //     url: MMCLocation.markerUrl,
      //   //     scaledSize: new google.maps.Size(100, 100),
      //   //   }
      //   // });
      //
      function loadSvgHomeMarker() {
        var googleMapsId = MMCLocation.google_map_url.split('cid=')[1];
        googleMapsId = googleMapsId.slice(0, googleMapsId.indexOf('&'));
        var mainMarker = new google.maps.Marker({
          //position: myLatLng,
          optimized: false,
          clickable: true,
          map: map,
          // setting the icon later in ajax
          title: MMCLocation.siteTitle,
          // zIndex: idx,
          animation: google.maps.Animation.DROP,
          position: myLatLng,
          place: {
            location: myLatLng,
            placeId: googleMapsId
          },
          icon: {
            url: MMCLocation.hotspotMarkerUrl,
            scaledSize: new google.maps.Size(59, 86),
          }
        });
        google.maps.event.addListener(mainMarker, 'click',
          function() {
            window.open(MMCLocation.google_map_url, '_blank');
          });
        MMCLocation.homeMapMarker = mainMarker;
      }
      loadSvgHomeMarker();

      afterAllMarkersHaveDropped = function() {
        // if (route.location) {
        //   setMarkerActive(MMCLocation.activeCategory, MMCLocation.locationMarkers[
        //       MMCLocation.activeCategory
        //     ][route.location],
        //     $('[data-fpid="' + route.location + '"]').addClass('active'));
        //   // only do this on page load, setting to false so it doesnt trigger again
        //   route.location = false;
        // }
        showAllMarkers();

      };
      // setup Page events for categorys/lists
      (function() {

        function initSlickSlider(unslick) {
          var unslickCheck = unslick || false;
          $wrapper = $('.locationnav__wrapper .sub-menu');
          for(var i = 0; i<$wrapper.length; i++){
            var $currentWrapper = $($wrapper[i]);
            if ($currentWrapper.hasClass("slick-initialized") && unslickCheck) {
              $currentWrapper.slick('unslick');
            }
            if (!$currentWrapper.hasClass("slick-initialized")) {
              var childElements = $currentWrapper.children().length;
              var printElements = childElements;
              if( childElements === 3 ){
                printElements = 2;
              }
              console.log( $(this) );
              $currentWrapper.slick({
                // arrows: false,
                prevArrow: '<i class="slick-prev fa fa-angle-double-left"></i>',
                nextArrow: '<i class="slick-next fa fa-angle-double-right"></i>',
                centerMode: true,
                centerPadding: '10px',
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                rows: 0,
                responsive: [
                  {
                    breakpoint: 0,
                    settings: "unslick",
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 1,
                    }
                  },{
                    breakpoint: 992,
                    settings: {
                      slidesToShow: ( 3 > (childElements-1) ) ? printElements-1 : 3,
                    }
                  },
                  {
                    breakpoint: 1550,
                    settings: {
                      slidesToShow: ( 5 > (childElements-1) ) ? printElements-1 : 5,
                    }
                  },
                ]
              });
            }
          }

        }
        // $(window).resize(_.debounce(function() { initSlickSlider($('.locationnav__catitem.active'), true); }, 250));
        initSlickSlider();

        var categoryLinkHook = '.locationnav__cat';
        var categoryElementSelector = '.locationnav__catitem';
        var activeClass = 'active';
        var $categoryHooks = $(categoryLinkHook);
        var $categoryElements = $(categoryElementSelector);

        // var $catnav__inner = $('.catnav__inner');
        $('.locnav').on('click', '.locationnav__hotspotitem', function(e) {
          e.preventDefault();
          $(this).parents('.locationnav__catitem.active').find('.locationnav__hotspotitem').removeClass('active');
          $(this).addClass('active');
          if ($('.locmap__mapinner').is(':visible')) {
            setMarkerActive(MMCLocation.activeCategory, MMCLocation.locationMarkers[
              MMCLocation.activeCategory
            ][$(this).data('fpid')],
              $(this));
          } else {
            var newWnd = window.open($(this).find('.locationnav__website').prop('href'), '_blank');
            newWnd.opener = null;
          }
        });

        $('.locationnav__website, .locationnav__directions').on('click', function(e) {
          e.stopPropagation();
        });

        $('.locationnav__title').on('click', function(e) {
          // $(this)
          //   .parents('.slick-slider')
          //   .slick(
          //     'slickGoTo',
          //     $(this).parents('.locationnav__hotspotitem').data('slick-index')
          //   );
          e.preventDefault();
        });

        $('.sub-menu').on('click', '.locationnav__hotspotitem', function(e) {
            // setMarkerActive(__activeCategory, _marker, thisLocItem);
            $('.locationnav__catitem.active .sub-menu').slick('slickGoTo', $(this).attr('data-slick-index'), false);
        });


        jQuery('.locationnav__wrapper').on('afterChange', '.sub-menu', function(event, slick, currentSlide) {
          // jQuery('.locationnav__catitem.active .sub-menu').slick('slickPrev');
          // google.maps.event.trigger(markers[$('.locationnav__catitem.active .sub-menu').slick('slickCurrentSlide')], 'click');
          // console.log(jQuery('.locationnav__catitem.active .sub-menu').slick('slickCurrentSlide'));
          var $currentSlide = $(this).find('.slick-current');
          // console.log($currentSlide);
          $currentSlide.trigger('click');
          // var $activeHotspot = $('.locationnav__catitem.active .sub-menu li.active');
          // var activeFpid = $activeHotspot.data('fpid');
          // $('[data-fpid="' + activeFpid + '"]').addClass('active');
          // console.log($('[data-fpid="' + activeFpid + '"]'));
        });

        function loadActiveCategory(categoryHook) {
          var clickedHook = categoryHook;
          var $hookParent = $(categoryHook).parents(categoryElementSelector);
          $hookParent.addClass(activeClass);
          if ($(window).width() < 768) {
            $hookParent.children('.sub-menu').not('.mCustomScrollbar').mCustomScrollbar();
            $hookParent.children('.sub-menu').mCustomScrollbar("update");

            var scrollEvents = "scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove";
            var $page = $('html, body');
            // stop animation on scroll input from user
            $page.on(scrollEvents, function(){
              $page.stop();
            });
            $page
              .animate({
                scrollTop: $(categoryHook).offset().top - $('.mainHeader__stickynav').height()
              }, 1000)
              .promise()
              .then(function() {
                $page.off(scrollEvents);
              });

          } else {
            $hookParent.children('.sub-menu').not('.mCustomScrollbar').mCustomScrollbar("destroy");
          }
          $(categoryLinkHook).addClass(activeClass);
          var $activeCatElem = $hookParent;
          $activeCatElem.addClass(activeClass);
          MMCLocation.activeCategory = $activeCatElem.data('category');
          // $('.catnavlist')
          //   .removeClass(activeClass)
          //   .filter('[data-catlistid="' + MMCLocation.activeCategory +
          //     '"]')
          //   .addClass(activeClass);
          // $catnav__inner.removeClass(activeClass);
          addMarkerMethod($activeCatElem[0]);
        }

        function activateCategory(categoryElement) {
          $(categoryElementSelector).removeClass(activeClass);
          loadActiveCategory(categoryElement);
        }

        $('.sub-menu').on('click', function(e) {
          e.stopPropagation();
        });

        $categoryElements.on('click', function(e) {
          activateCategory(this.querySelector(categoryLinkHook));
        });

        $categoryHooks.on('click', function(e) {
          e.preventDefault();
        });

        $categoryElements.eq(0).trigger('click');

      })();

    }
  );
};
